import React from "react"
import BlogDetail from "../../components/Blog/BlogDetail"
import Layout from "../../components/Layout"
export default function BlogDetailPage() {
  
  


  return (
    <Layout>
      <BlogDetail />
  
    </Layout>
  )
}
